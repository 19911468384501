
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";
import emailingApi from "@/core/services/EmailingApi";
import { ElMessage, ElMessageBox } from "element-plus";

export default defineComponent({
    name: "builder",
    components: {},
    data() {
        return {
            campaignCount: "",
            loading: true,
            campaignSearch: "",
            campaigns: [] as any,
            currentPage: 1,
            totalPages: 0,
            selectedCampaigns: [] as any,
            actualFilter: "",
            actualCol: "",
            campaignsTh: [
                { label: "Title", class: "min-w-100px", colName: "title" },
                { label: "Subject", class: "min-w-100px", colName: "subject" },
                { label: "Emails", class: "min-w-60px", colName: "emails" },
                { label: "Delivery Date", class: "min-w-60px", colName: "delivery_date" },
                { label: "Status", class: "min-w-60px", colName: "status" },
            ],
            perm_pos: "",
        };
    },
    setup() {
        const router = useRouter();
        const resultsPerPage = 10;

        onMounted(() => {
            setCurrentPageBreadcrumbs("Campaigns List", []);
        });

        function createDebounce() {
            let timeout: any;
            return function (fnc, delayMs) {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    fnc();
                }, delayMs || 500);
            };
        }

        return { router, resultsPerPage, debounce: createDebounce() };
    },
    mounted() {
        this.getCampaigns();
        this.perm_pos = localStorage.getItem("perm_pos") as any;
    },
    methods: {
        handleSizeChange() {
            this.getCampaigns();
        },
        forceSend(id: any) {
            this.loading = true;
            emailingApi.forceSend(id).then((res) => {
                if (res.success) {
                    ElMessage({
                        message: "Campaign has been sent",
                        type: "success",
                    });
                    this.getCampaigns();
                } else {
                    ElMessage({
                        message: "An error occurred",
                        type: "error",
                    });
                }
            });
        },
        getCampaigns() {
            this.loading = true;

            var payload = {};

            if (this.actualFilter && this.actualCol) {
                // Si tri et pas de filtres
                payload = {
                    p: this.currentPage,
                    presult: this.resultsPerPage,
                    column: this.actualCol,
                    order: this.actualFilter,
                };
            } else {
                // Sinon on affiche tout
                payload = {
                    p: this.currentPage,
                    presult: this.resultsPerPage,
                };
            }

            if (this.campaignSearch) {
                payload = { ...payload, query: this.campaignSearch };
            }

            emailingApi
                .listCampaigns(payload)
                .then((res) => {
                    this.campaigns = res.data.campaign_list;
                    this.campaignCount = res.data.member_count;
                    this.totalPages = res.data.page_count;
                    this.campaigns.map((campaign: any) => {
                        campaign.delivery_date = new Date(campaign.delivery_date).toLocaleDateString();
                    });
                    this.loading = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.loading = false;
                });
        },
        editCampaign(id?: number) {
            if (id) this.router.push({ name: "campaign-details", params: { campaign_id: id } });
            else this.router.push({ name: "campaign-add" });
        },
        getCampaignStatus(status: any, scope: any) {
            if (scope === "status") {
                switch (status) {
                    case "DR":
                        return "Draft";
                    case "AR":
                        return "Archived";
                    case "SE":
                        return "Sent";
                }
            } else if (scope === "color") {
                switch (status) {
                    case "DR":
                        return "background-color: #C4C4C4";
                    case "AR":
                        return "background-color: #27347D";
                    case "SE":
                        return "background-color: #34CF85";
                }
            }
        },
        archiveCampaigns(id?: number) {
            ElMessageBox.confirm("Are you sure to delete this campaign?", "Delete campaign", {
                confirmButtonText: "Yes",
                cancelButtonText: "Cancel",
                customClass: "custom-modal",
                cancelButtonClass: "cancel-modal",
            })
                .then(() => {
                    emailingApi
                        .deleteCampaign(id)
                        .then(() => {
                            this.getCampaigns();
                            ElMessage({
                                type: "success",
                                message: "Campaign deleted",
                            });
                        })
                        .catch((err) => {
                            console.log(err);
                            ElMessage({
                                type: "error",
                                message: "An error occurred",
                            });
                        });
                })
                .catch(() => {
                    console.log("Canceled");
                });
        },
        sortColumn(column: string, id: number) {
            var arrows = document.getElementById("chevrons" + id);

            // Hide all arrows
            Array.from(document.getElementsByClassName("chevrons-container") as any).map((filter: any) => {
                filter.style.display = "none";
            });

            // Display the right arrows
            if (arrows) arrows.style.display = "flex";

            // If we click for the first time on a column OR if we change the column
            if (this.actualCol === "" || this.actualCol !== column) {
                this.actualCol = column;
                this.actualFilter = "asc";
                this.handleChevron("show", "up");
                this.handleChevron("hide", "down");

                // Else if we click on the same column
            } else if (this.actualCol === column) {
                if (this.actualFilter === "asc") {
                    this.handleChevron("hide", "up");
                    this.handleChevron("show", "down");
                    this.actualFilter = "desc";
                } else if (this.actualFilter === "desc") {
                    this.handleChevron("show", "up");
                    this.handleChevron("hide", "down");
                    this.actualFilter = "asc";
                }
            }
            this.getCampaigns();
        },
        handleChevron(action: string, direction: string) {
            var chevrons: any;

            if (direction === "up") chevrons = Array.from(document.getElementsByClassName("fa-chevron-up") as any);
            else if (direction === "down") chevrons = Array.from(document.getElementsByClassName("fa-chevron-down") as any);

            if (action === "show")
                chevrons.map((chevron: any) => {
                    chevron.classList.add("active-chevron");
                });
            else if (action === "hide")
                chevrons.map((chevron: any) => {
                    chevron.classList.remove("active-chevron");
                });
        },
        checkAll() {
            var topCheckbox = document.getElementById("topCheckbox") as any;
            var checkboxes = Array.from(document.getElementsByClassName("custom-checkbox-campaigns") as any);

            this.selectedCampaigns = [];

            if (topCheckbox.checked) {
                checkboxes.map((checkbox: any) => {
                    checkbox.checked = true;
                });

                this.campaigns.map((chapitre: any) => {
                    this.selectedCampaigns.push(chapitre.id);
                });
            } else {
                checkboxes.map((checkbox: any) => {
                    checkbox.checked = false;
                });
                this.selectedCampaigns = [];
            }
        },
        handleActionSelect(campaign: any) {
            var focusedCheckbox = document.getElementById("checkbox" + campaign.id) as any;

            if (focusedCheckbox?.checked) {
                this.selectedCampaigns.push(campaign);
            } else {
                this.selectedCampaigns.map((localCampaign: any) => {
                    if (campaign.id === localCampaign.id) {
                        this.selectedCampaigns.splice(this.selectedCampaigns.indexOf(localCampaign), 1);
                    }
                });
            }
        },
        handlePagination(pageNumber: any) {
            this.selectedCampaigns = [];
            this.currentPage = pageNumber;
            this.getCampaigns();
        },
        formatNumber(num) {
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
        },
    },
});
